<template>
  <div id="soldout-page">
    <vs-popup classContent="popup-example" :title="$t('makeOrder')" :active.sync="showModal">
      <table class="w-full border border-info border-solid border-grey rounded mb-5 p-1" v-if="getOrderItems.length">
        <tr style="border-bottom: 1px solid #333333;">
          <th>ID</th>
          <th>{{ $t('fields.title') }}</th>
          <th>{{ $t('fields.amount') }}</th>
        </tr>
        <tr v-for="item in getOrderItems" :key="item.id">
          <td class="px-3">{{item.id}}</td>
          <td class="px-3">{{item.name}}</td>
          <td class="px-3">{{item.quantity}}{{ $t('pcs') }}</td>
        </tr>
      </table>
      <div class="grid grid-cols-2 gap-4">
        <!-- <flat-pickr v-model="date" class="w-full inputx" placeholder="Sene saylan" /> -->
        <flat-pickr :config="configdateTimePicker" v-model="time" class="col-span-2 mb-4 w-full inputx" :placeholder="$t('deliveryTime')" />
        <vs-textarea class="col-span-2" :placeholder="$t('note')" v-model="note"></vs-textarea>
      </div>
      <vs-button @click="makeOrder" color="primary" type="filled">{{ $t('submit') }}</vs-button>
    </vs-popup>
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <template v-else-if="stock.length || unsupplied.length">
      <template v-if="stock.length">
        <div v-for="supplier in stock" :key="supplier.uuid">
          <vx-card collapse-action class="mb-6">
            <template #header>
              <div class="flex">
                <span class="text-lg mr-3">{{$t('supplier')}}:</span> 
                <h4 class="text-primary">{{ supplier.company_name }}</h4>({{ supplier.supplier_products.length }})
                <vs-button v-if="$acl.check('operator')" class="ml-auto" @click="submitOrder(supplier.uuid )"> <!-- icon-pack="feather" icon="icon-shopping-bag" -->
                  {{ $t('makeOrder') }}
                </vs-button>
              </div>
            </template>

            <ul class="vx-row">
              <li class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-4" v-for="prod in supplier.supplier_products" :key="prod.id">
                <vs-checkbox v-if="!prod.supplierorder.length || prod.supplierorder[0].status !== 'pending'" v-model="supplier.order" :vs-value="prod.id" :disabled="$acl.not.check('operator')" class="check-item"></vs-checkbox>
                <vx-card class="p-0 prod-card" :class="{'selected': isSelected(supplier.uuid, prod.id), 'ordered': prod.supplierorder.length}">
                  <div slot="no-body" class="px-4 pt-4">
                    <div class="vx-row px-4">
                      <div class="vx-col pl-0 w-auto img mb-4">
                        <img v-if="prod.product_images[0]" :src="`${$baseUrl}/products/preview/${prod.product_images[0].uuid}.webp`" alt="" class="responsive rounded-lg">
                        <span v-else class="rounded-lg">No image!</span>
                      </div>
                      <div class="vx-col px-0 mb-4">
                        <div class="h-12 overflow-hidden pr-4">
                          <span class="font-medium product-title">{{ prod.name_tm }}</span>
                        </div>
                        <vs-divider class="mt-1 mb-1"></vs-divider>
                        <div class="flex justify-between flex-wrap">
                          <span>
                            <p class="text-md text-danger font-medium">{{prod.stock_quantity}}{{$t('pcs')}}</p>
                          </span>
                          <span>
                            <p class="text-grey">min:{{prod.stock_min}}</p>
                            <!-- <p class="text-grey">dsfgh</p> -->
                          </span>
                        </div>
                        <p v-if="isSelected(supplier.uuid, prod.id)" class="mt-1">
                          <vs-input type="number" class="w-full" :placeholder="$t('quantity')" v-model="prod.orderQt" />
                        </p>
                        <p v-if="prod.supplierorder.length" class="border border-solid border-grey rounded-lg px-2 pt-2 pb-1 mt-1">
                          <!-- <vs-input disabled type="number" class="text-dark w-full" :placeholder="`Ordered: ${prod.supplierorder[0].Soproducts.quantity} pcs.`" /> -->
                          <span>{{$t('ordered')}}: </span><span class="font-medium">{{ prod.supplierorder[0].Soproducts.quantity }}{{$t('pcs')}}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </vx-card>
              </li>
            </ul>
            <vs-alert color="danger" closable class="mt-5" :active.sync="supplier.showAlert" close-icon="icon-x" icon-pack="feather" icon="icon-info">
              {{ errorText }}
            </vs-alert>
          </vx-card>
        </div>
      </template>
      <template v-if="unsupplied.length">
        <div>
          <vx-card collapse-action class="mb-6">
            <template #header>
              <div class="flex">
                <span class="text-lg mr-3">{{$t('supplier')}}:</span> 
                <h4 class="text-primary">Unsupplied</h4>({{ unsupplied.length }})
              </div>
            </template>
            <ul class="vx-row">
              <li class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-4" v-for="prod in unsupplied" :key="prod.id">
                <vx-card class="p-0 prod-card">
                  <div slot="no-body" class="px-4 pt-4">
                    <div class="vx-row px-4">
                      <div class="vx-col pl-0 w-auto img mb-4">
                        <img v-if="prod.product_images[0]" :src="`${$baseUrl}/products/preview/${prod.product_images[0].uuid}.webp`" alt="" width="100px" class="rounded-lg">
                        <span v-else class="rounded-lg">No image!</span>
                      </div>
                      <div class="vx-col px-0 mb-4 prod-descr">
                        <div class="h-12 overflow-hidden pr-4 product-title">
                          <span class="font-medium">{{ prod.name_tm }}</span>
                        </div>
                        <vs-divider class="mt-1 mb-1"></vs-divider>
                        <div class="flex justify-between flex-wrap">
                          <span>
                            <p class="text-md text-danger font-medium">{{prod.stock_quantity || 0}}{{$t('pcs')}}</p>
                          </span>
                          <span>
                            <p class="text-grey">min:{{prod.stock_min || 0}}</p>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </vx-card>
              </li>
            </ul>
          </vx-card>
        </div>
      </template>
    </template>
    <template v-else>
      No products out of stock!
    </template>
  </div>
</template>

<script>
import 'flatpickr/dist/flatpickr.css'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
export default {
  components: {
    flatPickr: () => import('vue-flatpickr-component')
  },
  data () {
    return {
      stock: [],
      unsupplied: [],
      date: null,
      time: null,
      configdateTimePicker: {
        enableTime: true,
        time_24hr: true,
        locale: Russian
      },
      isLoading: true,
      showAlert: false,
      showModal: false,
      current: null,
      errorText: '',
      note: ''
    }
  },
  computed: {
    isSelected () {
      return (uuid, id) => {
        const supplier = this.stock.find(supp => supp.uuid === uuid)
        if (supplier.order) return (supplier.order.indexOf(id) !== -1)
        else return false
      }
    },
    getOrderItems () {
      const items = []
      if (this.current) {
        const supplier = this.stock.find(supp => supp.uuid === this.current)
        if (supplier.order.length) {
          supplier.order.forEach(el => {
            const idx = supplier.supplier_products.find(item => item.id === el)
            items.push({
              id: idx.id,
              name: idx.name_tm,
              quantity: idx.orderQt
            })
          })
        }
      }
      return items
    }
  },
  async created () {
    await this.fetchData()
  },
  methods: {
    async fetchData () {
      await this.$http.get('products/stock').then(response => {
        this.stock = response.data.suppliers.map(el => { return { ...el, order: [], showAlert: false } })
        this.unsupplied = response.data.unsupplied_products
        this.isLoading = false
      }).catch(() => { this.isLoading = false })
    },
    async submitOrder (uuid) {
      const supplier = this.stock.find(supp => supp.uuid === uuid)
      if (!supplier.order.length) {
        this.errorText = 'Choose at least one product and enter order quantity'
        supplier.showAlert = true
      } else {
        this.current = uuid
        for (const el of supplier.order) {
          if (!supplier.supplier_products.find(e => e.id === el).orderQt) {
            this.errorText = 'Enter amount of ordered item'
            this.showModal = false
            supplier.showAlert = true
            return false
          } else {
            supplier.showAlert = false
            this.showModal = true
            continue
          }
        }
        return true
      }
    },
    async makeOrder () {
      const data = {
        delivery_time: this.time,
        note: this.note,
        supplierId: this.stock.find(supp => supp.uuid === this.current)['id'],
        products: this.getOrderItems.map(el => { return {id: el.id, quantity: Number(el.quantity) } })
      }
      await this.$http.post('suppliers/orders/add', data).then(response => {
        this.showModal = false
        if (response.status === 201) {
          this.$vs.notify({
            title: 'Success',
            text: 'Order successfully sended!',
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'primary'
          })
          this.fetchData()
        } else {
          this.$vs.notify({
            title: 'Error',
            text: 'Something went wrong, please try again later!',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }).catch(err => {
        this.$vs.notify({
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#soldout-page {
  .img {
    height: 100px !important;
    width: 110px !important;
    overflow: hidden;
    img {
      width: 100% !important;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
    span {
      padding: 10px;
      display: block;
      height: 100%;
      width: 100%;
      background-color: #ededed;
    }
  }
  .vx-card {
    .vx-row {
      .vx-col {
        position: relative;
        .prod-card {
          outline: 1px solid rgb(235, 235, 235);
        }
        .selected {
          outline: 2px solid rgb(24, 179, 57) !important;
        }
        .ordered {
          outline: 2px solid #7367F0 !important;
        }
        .check-item {
          position: absolute;
          top: 13px;
          right:17px;
          z-index: 12;
          border-radius: 12px;
        }
        .vs-component.vs-checkbox-default {
          // position: relative;
          display: block;
          .vs-checkbox {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        .prod-descr {
          width: calc(100% - 120px);
        }
        .product-title {
          // width: 100%;
          height: 40px;
          overflow: hidden;
          text-overflow: ellipsis;
          span {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>